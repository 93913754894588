import instance from '@/request/request'
import axios from 'axios';
import instances from "@/request/request";

export function login(data) {
    return axios({
        method: 'POST',
        url: "/api/login/",
        data: data,
    });
}

//手机号登录
export function getmobileUser(data) {
    return axios({
        method: 'GET',
        url: `/api/system/user_select/?${data}`,
    });
}
//15076277953   手机号
//yanzhengma
export function getcodeUser(data) {
    return axios({
        method: 'POST',
        url: `/api/phone/code/`,
        data
    });
}
//单点登录
export function autoLogin(data) {
    return axios({
        method: 'POST',
        url: `/api/login/username/`,
        data
    });
}
//手机号登录
export function codeLoginUser(data) {
    return axios({
        method: 'POST',
        url: `/api/phonelogin/`,
        data
    });
}
//获取未读消息数量
export function GetUnreadMessageQuantity () {
    return instances({
        url: `/api/system/message_center/get_unread_msg/`,
        method: 'get'
    })
}