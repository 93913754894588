import { createRouter, createWebHistory } from 'vue-router'
import {clearSessionStorage, deleteCookie, getCookieValue} from "@/views/utls";
import {ElMessage} from "element-plus";
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/InformationQuery/InformationQuery.vue')//信息查询
  },
  {
    path: '/InformationQuery',
    name: 'InformationQuery',
    component: () => import(/* webpackChunkName: "about" */ '../views/InformationQuery/InformationQuery.vue')//信息查询
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/RecruitmentAcquisition/RecruitmentAcquisitionView.vue')//招采中心
  // },
  {
    path: '/LogIn',
    name: 'LogIn',
    component: () => import(/* webpackChunkName: "about" */ '../views/LogIn/LogIn.vue')//招采中心
  },
  {
    path: '/LogInUser',
    name: 'LogInUser',
    component: () => import(/* webpackChunkName: "about" */ '../views/LogIn/LoginSelectUser.vue')//招采中心
  },
  {
    path: '/IndividualUser',
    name: 'IndividualUser',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sign/IndividualUser.vue')//招采中心
  },
  {
    path: '/EnterpriseCertification',
    name: 'EnterpriseCertification',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sign/EnterpriseCertification.vue')//招采中心
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/userView/ForgotPassword.vue')//招采中心
  },
  // {
  //   path: '/UserView',
  //   name: 'UserView',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/VipBox/UserView.vue')//招采中心
  // },
  {
    path: '/VipBox',
    name: 'VipBox',
    component: () => import(/* webpackChunkName: "about" */ '../views/VipBox/VipBox.vue')//招采中心
  },
  // ForgotPassword
  {
    path: '/RecruitmentAcquisitionView',
    name: 'RecruitmentAcquisitionView',
    component: () => import(/* webpackChunkName: "about" */ '../views/RecruitmentAcquisition/RecruitmentAcquisitionView.vue'),//招采中心

  },
  {
    path: '/SeeMore',
    name: 'SeeMore',
    component: () => import(/* webpackChunkName: "about" */ '../views/RecruitmentAcquisition/SeeMore.vue')//
  },
  {
    path: '/DetailsView',
    name: 'DetailsView',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectLnquiry/DetailsView.vue')//
  },
  // DetailsView
  {
    path: '/ModeBidding',
    name: 'ModeBidding',
    component: () => import(/* webpackChunkName: "about" */ '../views/ModeBidding/ModeBidding.vue')//招标模式
  },
  
  {
    path: '/TendingDetail/:id',
    name: 'TendingDetail',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/InformationQuery/TendingDetail.vue')//信息查询
  },
  {
    path: '/AnnouncementWinningBid',
    name: 'AnnouncementWinningBid',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/AnnouncementWinningBid/AnnouncementWinningBid.vue')//信息查询
  },
  // AnnouncementWinningBid
  {
    path: '/bindingScreen',
    name: 'bindingScreen',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/BiddingScreen.vue')//竞价大屏
  },
    // 综合大屏
  {
    path: '/BiddingScreenComprehensive',
    name: 'BiddingScreenComprehensive',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/BiddingScreenComprehensive.vue')
  },
   // 合理价大屏
  {
    path: '/biddingScreenFair',
    name: 'biddingScreenFair',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/biddingScreenFair.vue')
  },
  //报价页面
  {
    path: '/biddingInformation',
    name: 'biddingInformation',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/BiddingInformation.vue')//报价页面
  },
  //公开比选报价页面
  {
    path: '/OpenBiddingQuote',
    name: 'OpenBiddingQuote',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/OpenBiddingQuote.vue')//报价页面
  },
  //公开比选报价页面
  {
    path: '/OpenBiddingQuoting',
    name: 'OpenBiddingQuoting',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/OpenBiddingQuoting.vue')//报价页面
  },
  //公开比选报价大屏
  {
    path: '/OpenBiddingScreen',
    name: 'OpenBiddingScreen',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/OpenBiddingScreen.vue')//报价大屏
  },
  //公开比选中标公示
  {
    path: '/OpenBiddingPublicity',
    name: 'OpenBiddingPublicity',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/OpenBiddingPublicity.vue')//中标公示
  },
  {
    path: '/checkAllQuote',
    name: 'checkAllQuote',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/BiddingVIew/checkAllQuote.vue')//查看全部报价
  },
  {
    path: '/ProjectLnquiry',
    name: 'ProjectLnquiry',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectLnquiry/ProjectLnquiry.vue')//项目查询
  },
  {
    path: '/DetailsViews/:id',
    name: 'DetailsViews',
    props: true, // 将路由参数作为组件的 props
    component: () => import(/* webpackChunkName: "about" */ '../views/RecruitmentAcquisition/DetailsViews.vue')//项目查询
  },
  {
    path: '/MyInvolvement',
    name: 'MyInvolvement',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/MyInvolvement.vue'),//我的参与
    children:[
      {
        path: '/MyInvolvement',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/GetInvolvedView.vue')
      },
      {
        path: '/MyInvolvement/GetInvolvedView',
        name: 'GetInvolvedView',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/GetInvolvedView.vue')//
      },
      {
        path: '/MyInvolvement/WonTheBid',
        name: 'WonTheBid',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/WonTheBid.vue')//我的中标
      },

      {
        path: '/MyInvolvement/IWonTheBidView',
        name: 'IWonTheBidView',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/IWonTheBidView.vue')//中标详情
      },
      {
        path: '/MyInvolvement/MyCertificationView',
        name: 'MyCertificationView',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/MyCertificationView.vue')//我的认证
      },
      {
        path: '/MyInvolvement/ApplicationMargin',
        name: 'ApplicationMargin',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/SecurityDeposit/ApplicationMargin.vue')//保证金申请详情
      },
      {
        path: '/MyInvolvement/BidWinningServiceFee',
        name: 'BidWinningServiceFee',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/SecurityDeposit/BidWinningServiceFee.vue')//中标服务费
      },
      {
        path: '/MyInvolvement/DetailsDepositReturn',
        name: 'DetailsDepositReturn',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/SecurityDeposit/DetailsDepositReturn.vue')//中标服务费
      },
      {
        path: '/MyInvolvement/PaymentSecurityDeposit',
        name: 'PaymentSecurityDeposit',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/SecurityDeposit/PaymentSecurityDeposit.vue')//中标服务费
      },
      {
        path: '/MyInvolvement/RecordPayment',
        name: 'RecordPayment',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/SecurityDeposit/RecordPayment.vue')//中标服务费
      },
      {
        path: '/MyInvolvement/ReturnSecurityDeposit',
        name: 'ReturnSecurityDeposit',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/SecurityDeposit/ReturnSecurityDeposit.vue')//保证金退回申请
      },
      {
        path: '/MyInvolvement/MemberServices',
        name: 'MemberServices',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/MemberServices/MemberServices.vue')//保证金退回申请
      },
      {
        path: '/MyInvolvement/VipBox',
        name: 'VipBox',
        component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/VipBox.vue')//保证金退回申请
      },
      {
          path: '/MyInvolvement/UserView',
          name: 'UserView',
          component: () => import(/* webpackChunkName: "about" */ '../views/MyInvolvement/UserView.vue')//个人中心
        },
      // MemberServices
    ]

  },
  // MyInvolvement
  // {
  //   path: '/ModeBidding',
  //   name: 'ModeBidding',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/template')//其他模式
  // },
  // template
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
