import axios from 'axios'
import { clearSessionStorage, deleteCookie } from "@/views/utls";
import router from "@/router";
// import {useRouter} from "vue-router";
// const router = useRouter();
// 创建 axios 实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000,
    headers: {
        common: {
            'Authorization': 'Bearer YourDefaultAccessToken',
            // 其他请求头字段...
        },
    },
})

// 设置请求拦截器
instance.interceptors.request.use(config => {
    // 在发送请求之前做些什么，比如加入 token
    return config
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 设置响应拦截器
instance.interceptors.response.use(response => {
    if (response.data.code == 401) {
        console.log('Cookie 已过期，是否重新登录？')
        clearSessionStorage()
        deleteCookie('access_token')
        router.push('/LogIn');
        // const confirmResult = window.confirm('Cookie 已过期，是否重新登录？');
        // if (confirmResult) {
        //     // 确认，重定向到登录页面
        //     clearSessionStorage()
        //     deleteCookie('access_token')
        //     router.push('/LogIn');
        // } else {
        //     // 取消，重定向到首页
        //     clearSessionStorage()
        //     deleteCookie('access_token')
        //     router.push('/RecruitmentAcquisitionView');
        // }
    } else {
        return response.data
    }
    // 对响应数据做点什么

}, error => {
    // console.log(error, '====================我是错误拦截2')
    // 对响应错误做点什么
    // return Promise.reject(error)
})

// 封装请求方法
// const request = (method, url, data) => {
//     return instance({
//         method,
//         url,
//         data
//     })
// }

export default instance
