// main.js

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import instance from "@/request/request";
import {clearSessionStorage, deleteCookie, getCookieValue} from "@/views/utls";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

const app = createApp(App);
app.config.globalProperties.userTypeView = false;
app.config.globalProperties.Upurl = 'https://xzc.cnzhongcai.com/api/';//图片上传前缀
app.config.globalProperties.ImgUrl = 'https://xzc.cnzhongcai.com/';// 图片显示前缀
app.config.globalProperties.$activeTab = '招采中心';
function getroute(inputString) {
    const secondSlashIndex = inputString.indexOf('/', inputString.indexOf('/') + 1);
    return inputString.substring(0, secondSlashIndex);
}
router.beforeEach((to, from, next) => {
    let cooke=getCookieValue('access_token')

    if (getroute(to.path)=='/MyInvolvement'){
        if (cooke){
            next();
        }else{
            const confirmResult = window.confirm('Cookie 已过期，是否重新登录？');
            if (confirmResult) {
                // 确认，重定向到登录页面
                clearSessionStorage()
                deleteCookie('access_token')
                router.push('/LogIn');
            } else {
                // 取消，重定向到首页
                clearSessionStorage()
                deleteCookie('access_token')
                app.config.globalProperties.userTypeView = false;
                router.push('/InformationQuery');
            }
        }

    } else{
        next();
    }

});

function setRem() {
    const baseFontSize = 10; // 将1rem设置为10px
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    // 动态设置designWidth为窗口宽度
    const designWidth = windowWidth;

    const rem = (windowWidth / designWidth) * baseFontSize;

    document.documentElement.style.fontSize = rem + 'px';
}

// 初始设置
setRem();

// 窗口大小改变时重新设置
window.addEventListener('resize', setRem);

// 设置全局属性



// 创建一个 provide
const globalURL = 'https://xzc.cnzhongcai.com/';
app.provide('$globalURL', globalURL);
// 使用 VXETable
app.use(VXETable);
// 挂载 ElementPlus，并指定中文语言包
app.use(ElementPlus, { locale: zhCn });
// 挂载应用
app.use(store).use(router).mount('#app');
// 设置请求实例的默认 headers
instance.defaults.headers.common['Authorization'] = `JWT ${getCookieValue('access_token')}`;
