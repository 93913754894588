<template>
  <div class="home">
    <el-dialog v-model="dialogVisible" title="退出登录" width="30%" :before-close="handleClose">
      <span>确定退出 {{ userDate.userList.name }} 登录</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="ConfirmWithdrawal">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <div class="header">
      <div class="header-left">
        <img style="cursor: pointer" @click="imgroute" src="./image/zhongcai.png">

        <div v-if="activeTab == '我的参与'" class="topnav">
          <p @click="handleClick('招采中心')" style="cursor: pointer;" :class="{ active: activeTab === '招采中心' }">招采中心</p>
          <p @click="handleClick('招标模式')" style="cursor: pointer;" :class="{ active: activeTab === '招标模式' }">招标模式</p>
          <!--          <p @click="handleClick('其他模式')" :class="{ active: activeTab === '其他模式' }">其他模式</p>-->
          <p @click="handleClick('信息查询')" style="cursor: pointer;" :class="{ active: activeTab === '信息查询' }">信息查询</p>
        </div>
        <div class="topnav" v-if="activeTab == '招标模式' || activeTab == '信息查询' || activeTab == '项目查询'">
        </div>
      </div>

      <div class="header-right" v-if="userDate.TypeView">
        <div class="del" v-if="activeTab !== '我的参与'">
          <img src="./image/443.png">
          <div class="dle1">
            <p class="delText" style="display: flex;justify-content: space-between;align-items: center"><span
                style="font-size: 1.2rem">咨询热线</span></p>
            <p class="delNum">400-136-8839</p>

          </div>
          <div class="dle1">
            <p class="delText" style="display: flex;justify-content: space-between;align-items: center"><span
                style="font-size: 1.2rem">保证金热线</span></p>
            <p class="delNum">031168026032</p>
          </div>
        </div>
        <div class="PictureHead">
          <img class="PictureHeadImg" :src="userDate.userList.avatar" v-if="userDate.userList.avatar?.includes('http')">
          <img class="PictureHeadImg" :src="urls + userDate.userList.avatar" v-else>
        </div>
        &nbsp;&nbsp;
        <p style="border: none;outline: none;">
          <el-badge :value="userDate.count" :show-zero="userDate.showZero" class="itemtag">
            <el-dropdown style="border: none;outline: none">
              <span>
                {{ userDate.userList.name }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu style="border: none;outline: none">
                  <el-dropdown-item @click="userView">个人中心</el-dropdown-item>
                  <el-dropdown-item @click="vipCenter">会员中心</el-dropdown-item>
                  <el-dropdown-item @click="DropOut">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-badge>
        </p>
      </div>
      <div v-if="!userDate.TypeView" style="display: flex;align-items: center">
        <div class="del" v-if="activeTab !== '我的参与'">
          <img src="./image/443.png">
          <div class="dle1">
            <p class="delText" style="display: flex;justify-content: space-between;align-items: center"><span
                style="font-size: 1.2rem">咨询热线</span></p>
            <p class="delNum">400-136-8839</p>

          </div>
          <div class="dle1">
            <p class="delText" style="display: flex;justify-content: space-between;align-items: center"><span
                style="font-size: 1.2rem">保证金热线</span></p>
            <p class="delNum">031168026032</p>

          </div>
        </div>
        <p style="height: 3rem;display: flex;">欢迎来到公开采购平台，<span style="color:#185be1;cursor: pointer"
            @click="userLogin">请登录</span> </p>
      </div>

    </div>
    <div class="main">
      <div v-if="activeTab !== '我的参与'" class="nav">
        <p @click="handleClick('招采中心')" style="cursor: pointer;" :class="{ active: activeTab === '招采中心' }">招采中心</p>
        <p @click="handleClick('招标模式')" style="cursor: pointer;" :class="{ active: activeTab === '招标模式' }">招标模式</p>
        <p @click="handleClick('信息查询')" style="cursor: pointer;" :class="{ active: activeTab === '信息查询' }">信息查询</p>
        <p @click="handleClick('我的参与')" style="cursor: pointer;" :class="{ active: activeTab === '我的参与' }">我的参与</p>
        <p @click="handleClick('项目查询')" style="cursor: pointer;" :class="{ active: activeTab === '项目查询' }">项目查询</p>
        <el-input v-if="activeTab === '招采中心' || activeTab === '招标模式' || activeTab === '信息查询'"
          style="display: flex; align-items: center; width: 30rem" v-model="input2" @change="KeyWords"
          placeholder="请输入招标公告关键词">
          <template v-slot:append>
            <span style="cursor:pointer" icon="el-icon-search" @click="KeyWords">搜索</span>
          </template>
        </el-input>
      </div>
      <div class="navBox">
        <router-view></router-view>
      </div>
    </div>
    <div class="bottons">
      <div class="bottonsTitle">中华集采·智行于信</div>
      <div class="bottonsmain">
        <div class="mainList">
          <ul>
            <li v-for="item in datalist.ContactUs" :key="item.id">
              <span :class="{ 'white-text': item.id == 1, 'blue-text': item.id !== 1 }">
                {{ item.name }}
              </span>
            </li>
          </ul>
          <ul>
            <li v-for="item in datalist.ChineseWisdom" :key="item.id">
              <span :class="{ 'white-text': item.id == 1, 'blue-text': item.id !== 1 }">
                {{ item.name }}
              </span>
            </li>
          </ul>
          <ul>
            <li v-for="item in datalist.FastConnection" :key="item.id">
              <span :class="{ 'white-text': item.id == 1, 'blue-text': item.id !== 1 }">
                {{ item.name }}
              </span>
            </li>
          </ul>
          <ul>
            <li v-for="item in datalist.SocialMedia" :key="item.id">
              <span :class="{ 'white-text': item.id == 1, 'blue-text': item.id !== 1 }">
                {{ item.name }}
              </span>
            </li>
          </ul>
        </div>
        <div class="ContactNumber">
          <div style="display: flex">
            <div class="numbertab">
              <div>
                <p class="TabText">客服咨询专线 : </p>
                <p class="Tab">400-136-8839</p>
              </div>
              <div>
                <p class="TabText">保证金咨询专线 : </p>
                <p class="Tab">0311-68026032</p>
              </div>

            </div>
          </div>
          <div class="shoukuan">
            <div class="douyin">
              <img src="./image/抖音.png">
              <p style="text-align: center;font-size: 1.2rem">公开采购平台抖音</p>
            </div>
            <div class="douyin"><img src="./image/gongzhonghao.png">
              <p style="text-align: center;font-size: 1.2rem">微信公众号</p>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="bottomView">
      <div class="BotList">
        <p>版权所有 : 河北建工集团供应链管理有限公司</p>
        <p>技术支持 : 北京中采智信技术有限公司</p>
        <p>地址 : 河北省石家庄市葵南路66号1号商务楼</p>
      </div>
      <div class="BotList">
        <p>冀ICP备13019655号-2</p>
        <p>经营许可证编号 : 冀B2-2018104</p>
        <p>冀公网安备 13010502001974号</p>
      </div>
    </div>
    <!-- <div style="width: 100%;background: #efefef;height: 10rem"></div> -->
  </div>
</template>

<script setup>
import { PhoneFilled } from "@element-plus/icons-vue";
import { ArrowDown } from '@element-plus/icons-vue'
import { getCurrentInstance, onMounted, reactive, ref, watchEffect } from 'vue'
import { getmobileUser, autoLogin, GetUnreadMessageQuantity } from "@/views/LogIn/api";
import { concatenateObjectProperties } from "@/views/utls";
import { clearSessionStorage, deleteCookie, getCookieValue, getSessionStorage, setCookie, setSessionStorage } from "@/views/utls";
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import { useRouter } from "vue-router";
const router = useRouter();
const Instance = getCurrentInstance()
import instance from "@/request/request";
let urls = Instance.appContext.config.globalProperties.ImgUrl

let userDate = reactive({
  TypeView: false,
  userList: {},
  count: '',
  showZero: false,

})
let activeTab = ref('信息查询')
const dialogVisible = ref(false)
const input2 = ref('')
let dataList = reactive({
  userList: [],
})

let datalist = reactive({
  ContactUs: [//联系我们
    { name: '招标注册', id: '1' },
    { name: '投标注册', id: '2' },
    { name: '查找项目', id: '3' },
    { name: '联系我们', id: '4' },
    { name: '保证金办理', id: '5' },
    { name: '投诉建议', id: '6' },
    { name: '新书指南', id: '7' },
  ],
  ChineseWisdom: [//联系我们
    { name: '智慧采购平台', id: '1' },
    { name: '软件制作', id: '2' },
    { name: '小程序开发', id: '3' },
    { name: '网页制作', id: '4' },
  ],
  FastConnection: [//联系我们
    { name: '河北建工集团官网', id: '1' },
    { name: '河北建工集团供应链管理有限公司', id: '2' },
    { name: '中采软件网', id: '3' },

  ],
  SocialMedia: [
    { name: '微信公众号', id: '1' },
    { name: '加入QQ圈', id: '2' },
    { name: '抖音号', id: '3' },
    { name: '快手号', id: '4' },
  ]
})
const DropOut = () => {
  dialogVisible.value = true
}
onMounted(() => {
  // 获取当前页面的查询字符串参数
  const params = new URLSearchParams(window.location.search);
  // 判断是否包含指定的键名（key）
  const mobile = params.get('mobile');
  if (params.has('mobile')) {
    let data = {
      mobile: mobile
    }
    if (!mobile) {
      return router.push('/Login')
    }
    // console.log(data)
    getmobileUser(concatenateObjectProperties(data)).then(res => {
      // console.log(res?.data?.data?.data.length)
      console.log("lala------", res)
      if (res?.data?.data?.data.length > 0) {
        dataList.userList = res?.data?.data?.data || []
        if (dataList.userList.length > 1) {
          console.log("多个用户")
          router.push({
            path: `/LogInUser`,
            query: { mobile: mobile }
          })
        } else if (dataList.userList.length = 1) {
          console.log("一个用户")
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          let data = {
            username: dataList.userList[0].username,
          }
          autoLogin(data).then(res => {
            console.log(res)
            if (res.data.code == 2000) {
              loading.close()
              let datas = res.data.data
              console.log(datas)
              setCookie('access_token', datas.access, 2)
              setSessionStorage('user', datas)
              instance.defaults.headers.common['Authorization'] = `JWT ${datas.access}`;
              ElMessage({
                message: '登录成功',
                type: 'success',
              })
              router.push({
                path: '/',
                query: { yourParamName: data.data }
              })
            } else {
              ElMessage({
                message: res.data.msg,
                type: 'warning',
              })
            }
          })
        } else {
          console.log("无用户")
          return ElMessage({
            message: '无用户',
            type: 'warning',
          })
        }
      } else {
        return ElMessage({
          message: res?.data?.msg,
          type: 'warning',
        })
      }

    })

  } else {
    // console.log("查询字符串中不包含'key'这个键");
  }
  gituser()

})
const gituser = () => {
  let datas = getSessionStorage('user')
  let usercooke = getCookieValue('access_token')
  if (datas !== null && usercooke !== null) {
    userDate.userList = datas
    userDate.TypeView = true
    GetUnreadMessageQuantity().then(res => {
      if (res) {
        userDate.count = res?.data?.count || ''
      }
    })
  } else {
    userDate.TypeView = false
  }
}
watchEffect(() => {
  gituser()
  let url = router.currentRoute.value.path
  // console.log(url)
  if (url == '/RecruitmentAcquisitionView') return activeTab.value = '招采中心'
  if (url == '/ModeBidding') return activeTab.value = '招标模式'
  if (url == '/InformationQuery') return activeTab.value = '信息查询'
  if (url == '/MyInvolvement') return activeTab.value = '我的参与'
  if (url == '/ProjectLnquiry') return activeTab.value = '项目查询'
  if (url?.includes("/TendingDetail")) return activeTab.value = '招采中心'
  if (url?.includes("/OpenBiddingQuote")) return activeTab.value = '招采中心'
  if (url == '/bindingScreen') return activeTab.value = '招采中心'
  if (url == '/biddingScreenFair') return activeTab.value = '招采中心'
  if (url == '/BiddingScreenComprehensive') return activeTab.value = '招采中心'
  if (url == '/biddingInformation') return activeTab.value = '招采中心'
  if (url == '/OpenBiddingQuoting') return activeTab.value = '招采中心'
  if (url == '/OpenBiddingScreen') return activeTab.value = '招采中心'

});
let handleClick = (value) => {
  if (value == '招采中心') {
    // router.push('/RecruitmentAcquisitionView')
    return ElMessage({
      message: '正在建设中',
      type: 'info',
    })
  }
  if (value == '招标模式') {
    // router.push('/ModeBidding');
    return ElMessage({
      message: '正在建设中',
      type: 'info',
    })
  }
  if (value == '信息查询') {
    router.push('/InformationQuery')
  }
  if (value == '我的参与') {
    let code = getCookieValue('access_token')
    if (code !== null) {
      activeTab.value = value
      return router.push('/MyInvolvement')
    } else {
      return ElMessage({
        message: '您还没有登录，请先登录',
        type: 'warning',
      })
    }
  }
  if (value == '项目查询') {
    router.push('/ProjectLnquiry')
  }
}
const userLogin = () => {
  router.push('/LogIn')
}
const vipCenter = () => {
  router.push('/MyInvolvement/VipBox')
}
const userView = () => {
  router.push('/MyInvolvement/UserView')
}
// 会员中心
// const VIPView=()=>{
//   router.push('/VipBox')
//   // /VipBox
// }
const imgroute = () => {
  router.push('/InformationQuery')
}
const handleClose = (done) => {
  ElMessageBox.confirm('Are you sure to close this dialog?')
    .then(() => {
      done()
    })
    .catch(() => {
      // catch error
    })
}
const ConfirmWithdrawal = () => {
  clearSessionStorage()
  deleteCookie('access_token')
  dialogVisible.value = false
  activeTab.value = '招采中心'
  ElMessage({
    message: '退出成功',
    type: 'success',
  })
  router.push('/')
  location.href = location.href;
}
const KeyWords = () => {
  router.push({
    path: '/InformationQuery',
    query: { keys: input2.value }
  })

}
</script>
<style lang="less" scoped>
.itemtag {
  margin-top: 20px;
  margin-right: 50px;
  cursor: pointer;
}

.dialog-footer button:first-child {
  margin-right: 1rem;
}

.home {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.header {
  width: 80%;
  height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
}

.header-left img {
  height: 60px;
}

.header-left {
  display: flex;
}

.header-right {
  display: flex;
  align-items: center;
  min-width: 10rem;
}

.header-right p {
  display: flex;
  align-items: center;
}

.PictureHead {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  overflow: hidden;
}

.PictureHeadImg {
  height: 4rem;
  width: 4rem;
}

.bottons {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* 垂直方向布局 */
  //justify-content: center; /* 垂直居中对齐 */
  align-items: center;
  /* 水平居中对齐 */
  margin-top: 5rem;
  margin-bottom: 5rem;
  height: 40rem;
  background: #fff;
}

.bottonsTitle {
  width: 80%;
  color: #ddae75;
  font-size: 35px;
  font-family: '宋体';
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px #e8eaec solid;
}

.white-text {}

.blue-text {}

.bottonsmain {
  display: flex;
  width: 80%;
  padding: 10px;
  justify-content: space-between;
}

.mainList {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.mainList li {
  padding: 10px;
}

.shoukuan {
  display: flex;
}

.douyin {
  padding: 1rem;
  width: 15rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.shoukuan img {
  width: 10rem;
  height: 10rem;

}

.ContactNumber {
  display: flex;

  justify-content: space-evenly;
  //justify-content: center; /* 垂直居中对齐 */
  align-items: center;
  /* 水平居中对齐 */
}

.numbertab {

  .TabText {
    padding: 1rem;
  }

  .Tab {
    padding: 1rem;
    font-size: 2rem;
    color: #6290f6;
  }
}

.main {
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  /* 垂直方向布局 */
  align-items: center;
}

.nav p {
  width: 120px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 1.8rem;
  margin: 0 2rem;
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
  background: #185be1;
  height: 50px;
  justify-content: center;
}

.topnav {
  width: 100%;
  display: flex;
  align-items: center;
  height: 5rem;
  justify-content: center;
  margin-left: 5rem;
}

.topnav p {
  width: 120px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
}

.active {
  background-color: #3c7eff;
  /* 添加背景色样式 */
  color: #fff;
  /* 设置文本颜色 */
}

.navBox {
  width: 100%;
  display: flex;
  justify-content: center;

}

.example-showcase .el-dropdown-link {
  border: none !important;
  outline: none !important;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
  border: none !important;
  outline: none !important;
}

.del {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  min-width: 50rem;
  height: 3rem;

  img {
    width: 3rem;
    height: 3rem;
  }

  .dle1 {
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  .delNum {
    padding: 0 0.5rem;
    color: #3f82f7;

  }
}

.delText {
  padding: 0 0.5rem;
  border-right: 1px #efefef solid;
}

.bottomView {
  width: 100%;
  border-top: 1px #efefef solid;
  padding: 20px 0;
}

.BotList {
  display: flex;

  p {
    width: 33%;
    padding: 2rem;
    text-align: center;
  }
}
</style>