import md5 from 'js-md5';
import moment from 'moment';
export const concatenateObjectProperties = (obj) => {
    return Object.keys(obj)
        .filter(key => obj[key] !== null && obj[key] !== undefined && obj[key] !== '')
        .map(key => `${key}=${obj[key]}`)
        .join('&');
};
export function hashPassword(password) {
    try {
        const hashedPassword = md5(password);
        return hashedPassword;
    } catch (error) {
        console.error('Error hashing password:', error);
        throw error;
    }
}
//储存cookie
export function setCookie(name, value, hours) {
    const expires = new Date();
    expires.setTime(expires.getTime() + hours * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}
//获取储存的cookie
export function getCookieValue(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return null;
}
// 看名字
export function setSessionStorage(key, value) {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error('Error storing data in sessionStorage:', error);
    }
}
// 看名字
export function getSessionStorage(key) {
    try {
        const storedValue = sessionStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
        console.error('Error retrieving data from sessionStorage:', error);
        return null;
    }
}
export function clearSessionStorage() {
    sessionStorage.clear();
}
export function deleteCookie(cookieName) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
export function formatToYYYYMMDD(date) {
    return moment(date).format('YYYY-MM-DD');
}
export function getCodeByName(data, name) {
    const foundItem = data.find(item => item.name === name);
    return foundItem ? foundItem.code : null;
}
export function getTitleById(id, dataArray) {

    const foundItem = dataArray.find(item => item.id == id);

    return foundItem ? foundItem.title : '全部';
}
export function getTitleByName(id, dataArray) {

    const foundItem = dataArray.find(item => item.id == id);

    return foundItem ? foundItem.name : '全部';
}
export function calculateMinutesRemaining(startTimeStr, endTimeStr) {
    try {
        // 使用 moment 解析输入的时间字符串
        const startTime = moment(startTimeStr, 'YYYY-MM-DD HH:mm:ss');
        const endTime = moment(endTimeStr, 'YYYY-MM-DD HH:mm:ss');

        // 计算时间差
        const timeDifference = endTime.diff(startTime, 'minutes');

        return timeDifference;
    } catch (error) {
        console.error("Error:", error.message);
        return null;
    }
}
export  function formatMoney(amount) {
    if (amount >= 100000000) {
        return `${(amount / 100000000).toFixed(2)} 亿`;
    } else {
        return `${(amount / 10000).toFixed(2)} 万`;
    }
}
export  function formatMoneys(amount) {
    if (amount >= 100000000) {
        return `${(amount / 100000000).toFixed(2)} `;
    } else {
        return `${(amount / 10000).toFixed(2)} `;
    }
}
export function calculateTotalQuantity(array) {
    // 使用 reduce 函数计算 quantity 的总和
    const totalQuantity = array.reduce((sum, item) => sum + (item.quantity || 0), 0);

    return totalQuantity;
}